import { LoaderFunctionArgs } from 'react-router-dom';
import DownloadPage from 'ui/pages/DownloadPage/DownloadPage';
import CddContributorAPI, {
	ContributorType,
} from 'utils/api/CddContributorAPI';
import { requestFormat } from 'utils/cddUtil/helpers';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const reportId = params.id as string;
	const format = requestFormat(request);
	const contributorType = params.type as string;
	if (!['cdd', 'ndd'].includes(contributorType.toLowerCase())) {
		throw new Error('Invalid contributor type');
	}

	const file = await CddContributorAPI.downloadReport(
		contributorType as ContributorType,
		reportId,
		format
	);

	if (file instanceof Error) {
		throw file;
	}

	downloadFile(file.url, file.filename);

	return null;
};

function downloadFile(exportFileUrl: string, fileName: string): void {
	const link = document.createElement('a');
	link.setAttribute('href', exportFileUrl);
	link.setAttribute('download', fileName);
	link.click();
}

export const CDD_CONTRIBUTOR_REPORT_DOWNLOAD_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <DownloadPage />,
};
