import { ENVIRONMENT_CLIENT_PORTAL, ENVIRONMENT_SHARED } from 'environment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	LoaderFunctionArgs,
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import ErrorBoundary from 'ui/components/ErrorBoundary';
import PageLoader from 'ui/components/PageLoader';
import {
	handlePendingLogout,
	isInIframe,
	mockMsalAccount,
	performMsalAuth,
} from 'utils/helpers/msal';
import { isTestRunner } from 'utils/helpers/test';
import App from './App';
import UserAPI from './api/UserAPI';
import { msalInstance } from './auth/authConfig';
import { LOGGED_IN_ROUTE } from './routes/auth/logged-in';
import { LOGGED_OUT_ROUTE } from './routes/auth/logged-out';
import { LOGIN_ROUTE } from './routes/auth/login';
import { LOGOUT_ROUTE } from './routes/auth/logout';
import { CDD_CONTRIBUTOR_ROUTES } from './routes/cdd-contributor';
import { HOME_ROUTE } from './routes/home/home';
import { PUBLICATIONS_DETAILS_ROUTE } from './routes/publications/publications-details';
import { PUBLICATIONS_DOWNLOAD_ROUTE } from './routes/publications/publications-download';
import { LIST_PUBLICATIONS_ROUTE } from './routes/publications/publications-list';
import { LIST_AIRLINES_ROUTE } from './routes/reference-data/airlines';
import { EXPORT_AIRLINES_ROUTE } from './routes/reference-data/airlines-export';
import { LIST_AIRPORTS_ROUTE } from './routes/reference-data/airports';
import { EXPORT_AIRPORTS_ROUTE } from './routes/reference-data/airports-export';
import { LIST_FREIGHT_FORWARDERS_ROUTE } from './routes/reference-data/freight-forwarders';
import { EXPORT_FREIGHT_FORWARDERS_ROUTE } from './routes/reference-data/freight-forwarders-export';
import { REFERENCE_DATA_ROUTE } from './routes/reference-data/reference-data';
import { LIST_REGIONS_ROUTE } from './routes/reference-data/regions';
import { EXPORT_REGIONS_ROUTE } from './routes/reference-data/regions-export';
import { REPORT_DOWNLOAD_ROUTE } from './routes/report-hub/report-download';
import { REPORT_HUB_ROUTE } from './routes/report-hub/report-hub';
import { REPORT_HUB_ADHOC_REPORTS_DOWNLOAD_ROUTE } from './routes/report-hub/report-hub-adhoc-download';
import { REPORT_HUB_ADHOC_LIST_ROUTE } from './routes/report-hub/report-hub-adhoc-list';
import { REPORT_HUB_BIP_LIST_ROUTE } from './routes/report-hub/report-hub-bip-list';
import { REPORT_HUB_DOWNLOAD_ROUTE } from './routes/report-hub/report-hub-download';
import { REPORT_HUB_WEB_TOOL_SCHEDULED_LIST_ROUTE } from './routes/report-hub/report-hub-scheduled-list';
import { REPORT_HUB_STATIC_REPORTS_ROUTE } from './routes/report-hub/report-hub-static-list';
import { REPORT_HUB_WEB_TOOL_LIST_ROUTE } from './routes/report-hub/report-hub-webtool-list';
import { WEB_TOOL_DOWNLOAD_ROUTE } from './routes/report-hub/webtool-download';
import { WEBTOOL_AIRLINE_GROUP_LIST_ROUTE } from './routes/web-tool-airline-groups/web-tool-airline-groups';
import { WEBTOOL_AIRLINE_GROUP_CREATE_ROUTE } from './routes/web-tool-airline-groups/web-tool-airline-groups-create';
import { WEBTOOL_AIRLINE_GROUP_EDIT_ROUTE } from './routes/web-tool-airline-groups/web-tool-airline-groups-edit';
import { WEBTOOL_BREAKS_GROUP_LIST_ROUTE } from './routes/web-tool-breaks-groups/web-tool-breaks-groups';
import { WEBTOOL_BREAKS_GROUP_CREATE_ROUTE } from './routes/web-tool-breaks-groups/web-tool-breaks-groups-create';
import { WEBTOOL_BREAKS_GROUP_EDIT_ROUTE } from './routes/web-tool-breaks-groups/web-tool-breaks-groups-edit';
import { WEBTOOL_CITY_GROUP_LIST_ROUTE } from './routes/web-tool-city-groups/web-tool-city-groups';
import { WEBTOOL_CITY_GROUP_CREATE_ROUTE } from './routes/web-tool-city-groups/web-tool-city-groups-create';
import { WEBTOOL_CITY_GROUP_EDIT_ROUTE } from './routes/web-tool-city-groups/web-tool-city-groups-edit';
import { WEBTOOL_FREIGHT_GROUP_LIST_ROUTE } from './routes/web-tool-freight-groups/web-tool-freight-groups';
import { WEBTOOL_FREIGHT_GROUP_CREATE_ROUTE } from './routes/web-tool-freight-groups/web-tool-freight-groups-create';
import { WEBTOOL_FREIGHT_GROUP_EDIT_ROUTE } from './routes/web-tool-freight-groups/web-tool-freight-groups-edit';
import { WEBTOOL_GEOGRAPHY_GROUP_LIST_ROUTE } from './routes/web-tool-geography-groups/web-tool-geography-groups';
import { WEBTOOL_GEOGRAPHY_GROUP_CREATE_ROUTE } from './routes/web-tool-geography-groups/web-tool-geography-groups-create';
import { WEBTOOL_GEOGRAPHY_GROUP_EDIT_ROUTE } from './routes/web-tool-geography-groups/web-tool-geography-groups-edit';
import { WEBTOOL_STATION_GROUP_LIST_ROUTE } from './routes/web-tool-station-groups/web-tool-station-groups';
import { WEBTOOL_STATION_GROUP_CREATE_ROUTE } from './routes/web-tool-station-groups/web-tool-station-groups-create';
import { WEBTOOL_STATION_GROUP_EDIT_ROUTE } from './routes/web-tool-station-groups/web-tool-station-groups-edit';
import { WEBTOOL_TRADE_GROUP_LIST_ROUTE } from './routes/web-tool-trade-groups/web-tool-trade-groups';
import { WEBTOOL_TRADE_GROUP_CREATE_ROUTE } from './routes/web-tool-trade-groups/web-tool-trade-groups-create';
import { WEBTOOL_TRADE_GROUP_EDIT_ROUTE } from './routes/web-tool-trade-groups/web-tool-trade-groups-edit';
import { WEBTOOL_GROUP_AREA_ROUTE } from './routes/web-tool/webtool-groups';
import { WEBTOOL_AREA_ROUTE } from './routes/web-tool/webtool-list';
import { WEBTOOL_REPORT_ROUTE } from './routes/web-tool/webtool-report';

export const rootLoader = async ({}: LoaderFunctionArgs) => {
	if (msalInstance.getActiveAccount() === null) {
		return null;
	}

	const userProfile = await UserAPI.getUserProfile().catch(() => null);
	if (!userProfile) return null;

	const enableWebTool =
		userProfile?.reportCounts.totalWebTool > 0 &&
		ENVIRONMENT_SHARED.featureFlags.enableWebTool;
	const enableCddContributor = userProfile?.cddContributorEnabled;
	const enableNddContributor = userProfile?.nddContributorEnabled;

	return {
		userProfile,
		enableWebTool,
		enableCddContributor,
		enableNddContributor,
	};
};

// Make MSAL available
if (!window.msal) {
	window.msal = {
		instance: msalInstance,
		scope: ENVIRONMENT_CLIENT_PORTAL.msal.scope,
	};
}

const makeRouter = () =>
	createBrowserRouter(
		createRoutesFromElements(
			<Route errorElement={<ErrorBoundary isRoot />} loader={rootLoader}>
				<Route path="logged-in" {...LOGGED_IN_ROUTE} />

				<Route path="logout" {...LOGOUT_ROUTE} />
				<Route path="logged-out" {...LOGGED_OUT_ROUTE} />

				<Route
					path="/report-hub/reports/download/:reportId"
					{...REPORT_DOWNLOAD_ROUTE}
				/>

				<Route
					path="/report-hub/download/:reportIds"
					{...REPORT_HUB_DOWNLOAD_ROUTE}
				/>

				<Route
					path="/report-hub/adhoc-reports/download/:reportIds"
					{...REPORT_HUB_ADHOC_REPORTS_DOWNLOAD_ROUTE}
				/>

				{/* NOTE: changes to this URL must be reflected in WebToolNotification.Function::SendExportNotification */}
				<Route
					path="/report-hub/webtool-reports/:reportId/download"
					{...WEB_TOOL_DOWNLOAD_ROUTE}
				/>

				<Route
					path="/reference-data/airline/export"
					{...EXPORT_AIRLINES_ROUTE}
				/>

				<Route
					path="/reference-data/airport/export"
					{...EXPORT_AIRPORTS_ROUTE}
				/>

				<Route
					path="/reference-data/publications/:publicationId/download"
					{...PUBLICATIONS_DOWNLOAD_ROUTE}
				/>

				<Route
					path="/reference-data/freight-forwarder/export"
					{...EXPORT_FREIGHT_FORWARDERS_ROUTE}
				/>
				<Route path="/reference-data/region/export" {...EXPORT_REGIONS_ROUTE} />

				<Route path="/" element={<App />}>
					<Route errorElement={<ErrorBoundary />}>
						<Route path="login" {...LOGIN_ROUTE} />
						<Route index {...HOME_ROUTE} />

						<Route path="report-hub" {...REPORT_HUB_ROUTE}>
							<Route
								path="static-reports"
								{...REPORT_HUB_STATIC_REPORTS_ROUTE}
							/>
							<Route path="bip-exports" {...REPORT_HUB_BIP_LIST_ROUTE} />
							<Route path="adhoc-reports" {...REPORT_HUB_ADHOC_LIST_ROUTE} />
							<Route
								path="webtool-reports"
								{...REPORT_HUB_WEB_TOOL_LIST_ROUTE}
							/>
							<Route
								path="scheduled-reports"
								{...REPORT_HUB_WEB_TOOL_SCHEDULED_LIST_ROUTE}
							/>
						</Route>

						<Route path="webtool" {...WEBTOOL_AREA_ROUTE}>
							<Route
								path=":id"
								{...WEBTOOL_REPORT_ROUTE}
								errorElement={<ErrorBoundary />}
							/>
						</Route>

						<Route path="webtool/groups" {...WEBTOOL_GROUP_AREA_ROUTE}>
							<Route path="airline" {...WEBTOOL_AIRLINE_GROUP_LIST_ROUTE} />
							<Route
								path="airline/create"
								{...WEBTOOL_AIRLINE_GROUP_CREATE_ROUTE}
							/>
							<Route path="airline/:id" {...WEBTOOL_AIRLINE_GROUP_EDIT_ROUTE} />

							<Route path="station" {...WEBTOOL_STATION_GROUP_LIST_ROUTE} />
							<Route
								path="station/create"
								{...WEBTOOL_STATION_GROUP_CREATE_ROUTE}
							/>
							<Route path="station/:id" {...WEBTOOL_STATION_GROUP_EDIT_ROUTE} />

							<Route path="freight" {...WEBTOOL_FREIGHT_GROUP_LIST_ROUTE} />
							<Route
								path="freight/create"
								{...WEBTOOL_FREIGHT_GROUP_CREATE_ROUTE}
							/>
							<Route path="freight/:id" {...WEBTOOL_FREIGHT_GROUP_EDIT_ROUTE} />

							<Route path="geography" {...WEBTOOL_GEOGRAPHY_GROUP_LIST_ROUTE} />
							<Route
								path="geography/create"
								{...WEBTOOL_GEOGRAPHY_GROUP_CREATE_ROUTE}
							/>
							<Route
								path="geography/:id"
								{...WEBTOOL_GEOGRAPHY_GROUP_EDIT_ROUTE}
							/>

							<Route path="city" {...WEBTOOL_CITY_GROUP_LIST_ROUTE} />
							<Route path="city/create" {...WEBTOOL_CITY_GROUP_CREATE_ROUTE} />
							<Route path="city/:id" {...WEBTOOL_CITY_GROUP_EDIT_ROUTE} />

							<Route path="breaks" {...WEBTOOL_BREAKS_GROUP_LIST_ROUTE} />
							<Route
								path="breaks/create"
								{...WEBTOOL_BREAKS_GROUP_CREATE_ROUTE}
							/>
							<Route path="breaks/:id" {...WEBTOOL_BREAKS_GROUP_EDIT_ROUTE} />

							<Route path="trade" {...WEBTOOL_TRADE_GROUP_LIST_ROUTE} />
							<Route
								path="trade/create"
								{...WEBTOOL_TRADE_GROUP_CREATE_ROUTE}
							/>
							<Route path="trade/:id" {...WEBTOOL_TRADE_GROUP_EDIT_ROUTE} />
						</Route>

						<Route path=":type-contributor">
							<Route index {...CDD_CONTRIBUTOR_ROUTES.list} />
							<Route path="upload" {...CDD_CONTRIBUTOR_ROUTES.upload} />
							<Route path=":id" {...CDD_CONTRIBUTOR_ROUTES.download} />
						</Route>

						<Route path="publications" {...LIST_PUBLICATIONS_ROUTE}>
							<Route path=":publicationId" {...PUBLICATIONS_DETAILS_ROUTE} />
						</Route>

						<Route path="reference-data" {...REFERENCE_DATA_ROUTE}>
							<Route path="airlines" {...LIST_AIRLINES_ROUTE} />
							<Route path="airports" {...LIST_AIRPORTS_ROUTE} />
							<Route path="regions" {...LIST_REGIONS_ROUTE} />
							<Route
								path="freight-forwarders"
								{...LIST_FREIGHT_FORWARDERS_ROUTE}
							/>
						</Route>
					</Route>
				</Route>
			</Route>
		)
	);

const mount = async () => {
	if (window.location.pathname === '/logged-in' && isInIframe()) {
		document.body.innerHTML = '';
		return;
	}

	const pageLoaderStatus = document.getElementById('pageLoaderStatus');
	if (pageLoaderStatus) {
		pageLoaderStatus.textContent = 'Authenticating...';
	}

	await msalInstance.initialize();

	if (!isTestRunner) {
		const redirectResult = await msalInstance.handleRedirectPromise();
		window.msal.redirectResult = redirectResult ?? undefined;

		await performMsalAuth(
			msalInstance,
			ENVIRONMENT_CLIENT_PORTAL.msal.scope
		).catch(() => null);

		window.addEventListener('focus', handlePendingLogout);
	} else {
		mockMsalAccount(msalInstance);
	}

	const rootElement = document.getElementById('root');
	if (!rootElement) {
		throw new Error('Root element not found');
	}

	const router = makeRouter();
	window.router = router;
	const reactRoot = ReactDOM.createRoot(rootElement);

	reactRoot.render(
		<React.StrictMode>
			<RouterProvider
				router={router}
				fallbackElement={<PageLoader label="Initializing CargoIS..." />}
			/>
		</React.StrictMode>
	);
};

mount();
